<template>
  <el-cascader
    v-model="selectValue"
    :options="options"
    :props="optionsPros"
    @change="change"
  ></el-cascader>
</template>

<script>
import {getAllChildrenList} from '@/api'
export default {
  name: 'index.vue',
  async created () {
    try{
      const res = await getAllChildrenList({
        type:2
      });
      const data = res.data.data;
      this.setSelectData(data);
    }catch (e) {
    }
  },
  data(){
    return {
      options:[],
      selectValue:"",
      optionsPros:{
        value:'id',
        label:'name',
        children:'children',
        checkStrictly:  true
      }
    }
  },
  methods:{
    change(){
      this.$emit("selectValue",this.selectValue)
    },
    setSelectData(data){
      let selectArr = data.department.map(item => {
        if(item.sentry && item.sentry.length > 0) {
          item.children = item.sentry
        }
        return item;
      })
      selectArr.unshift({
        name:"全部",
        id:"all"
      })
      this.options = selectArr
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner{
  width: 300px;
}
</style>
