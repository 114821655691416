<template>
  <div class="wrapper">
    <div class="icon">
      <img :src="item.icon">
    </div>
    <div class="content">
      <span class="title">{{ item.title }}</span>
      <span class="num">{{ item.content }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reportCard',
  props:{
    item:{
      type:Object
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper{
  width: 292px;
  height: 130px;
  background: #FDE5E4;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  .icon{
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
    justify-content: space-between;
    .title{
      font-size: 14px;
      font-family: PingFang SC;
      color: #E32322;
      margin-top: 5px;
    }
    .num{
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #E32322;
    }
  }
}
</style>
